import React, { useState , useEffect, useCallback, useRef, useMemo } from 'react';
import socketIOClient from 'socket.io-client'
import logo from './logo.svg';
import './App.css';
import { MapContainer, TileLayer, GeoJSON, LayersControl, LayerGroup,Marker, Tooltip, FeatureGroup, Polygon, Popup} from 'react-leaflet'
import MarkerClusterGroup from "react-leaflet-cluster";
import { useMapEvents } from 'react-leaflet/hooks'
import { Icon } from "leaflet";
import L from 'leaflet';
import LayerControl, { GroupedLayer } from "./LayerControl";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/images/marker-shadow.png";
import axios from 'axios';
import Client from './components/client';
import useAxios from "axios-hooks";

import ShapeComponent from "./components/ShapeComponent";
import InfoControl from "./components/InfoControl";
import LegendControl from "./components/LegendControl";
import DataComponent from "./components/DataComponent";
import AfiliacionesComponent from "./components/AfiliacionesComponent";

var myIcon_default = L.icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
  })

const removeAccents = str =>
str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

// DEFINICIÓN DEL ICONO MARKER
function get_icon_color(lider){
  //console.log(lider)
  if (removeAccents(lider)==="Jose Manuel Perez Najera".toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png',
      })
    return myIcon
  } else if (removeAccents(lider)==="Margot Castro Leal".toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png',
      })
    return myIcon
  } else if (removeAccents(lider)==="Alfredo Miguel Moran Moguel".toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Guillermo Montaño Garcia'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Ana Lidia Banuelos Diaz'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Norma Edith Martinez Guzman'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Rosa Icela Cardenas'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Francisco Javier Hernandez Figueroa'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
      })
    return myIcon
  } else {
    console.log(lider,removeAccents(lider))
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png',
      })
    return myIcon
  }
}

export default function App() { // COMPONENTE DE LA APLICACIÓN PARA IMPORTAR: <App />
  const [lideres, setLider] = useState([]);
  const fetchData = async () => {
    const result = await Client.get();
    setLider(result);
  };     
  // AXIOS REQUEST API GET
    useEffect(()=>{
      setInterval(() => {
        fetchData();
      },8000)
    },[])
  
  var count_seccion_electoral = []
  
  lideres.data?.map((item) => { 
    count_seccion_electoral.push(item.section)
    })
  
  var dicty_seccion_electoral = [];
  count_seccion_electoral.forEach(function(element) {
    dicty_seccion_electoral[element]=count_seccion_electoral.filter(x => x === element).length
  });
  
  console.log(dicty_seccion_electoral[2007])

  const infoRef = useRef();
  return (
    <MapContainer center={[23.13,-101.15]} zoom={5} dragging={true} touchZoom={true} scrollWheelZoom={true} doubleClickZoom={false}>
      <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <InfoControl ref={infoRef} />
      <LayersControl position="topleft" collapsed={false}>
        <LayersControl.Overlay name="Lideres">      
          <LayerGroup>
            <MarkerClusterGroup maxClusterRadius={150} spiderfyOnMaxZoom={true} showCoverageOnHover={true}> 
                {lideres.data?.map((item) => { 
                  return (
                    <Marker key={item.id} position={item.coordenada} icon={get_icon_color(item.lider)}>
                      <Tooltip direction="top" style={{ backgroundColor: "rgb(0, 255, 30)", color: "#222" }}>
                        {/* <span>{"lider: "+item.lider} <br /> {"Sección: "+item.id_sección} <br /> {item.lider}</span> */}
                        <span>
                          <b>{"Lider: "}</b>{item.lider} 
                        <br /> <b>{"Último movimiento: "}</b>{item.updatedAt}</span>
                      </Tooltip>
                    </Marker>
                  )}
                )}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Afiliados"> 
            <LayerGroup>
              <MarkerClusterGroup maxClusterRadius={150} spiderfyOnMaxZoom={true} showCoverageOnHover={true} > 
                {lideres.data?.map((item) => { 
                  //console.log(item)
                  return (
                    <Marker key={item.id} position={item.placeIndex} icon={get_icon_color(item.lider)}>
                      <Popup direction="top" style={{ backgroundColor: "rgb(0, 255, 30)", color: "#222" }}>
                        {/* <span>{"lider: "+item.lider} <br /> {"Sección: "+item.id_sección} <br /> {item.lider}</span> */}
                        <div>
                        <b> {"Nombre del Afiliado: "}</b>{item.name+" "+item.last_name}                  
                        <br /> <b>{"Sección electoral: " }</b>{item.section}
                        <br /> <b>{"Afiliados en esta sección electoral: " }</b>{dicty_seccion_electoral[item.section]}
                        <br />
                        <br /> <b>{"Lider encargado: "}</b>{item.lider} 
                        <br /> <b>{"Fecha del registro: "}</b>{item.fecha_registro_afiliado}</div>
                      </Popup>
                    </Marker>
                  )}
                )}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  );
}
