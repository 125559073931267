import { GeoJSON, useMap } from "react-leaflet";
import secc_elec_shape from "../data/geojson_coyoacán.json"; //secciones electorales coyoacan
import afiliaciones_shape from "../data/geojson_afiliados_update.json"; //secciones electorales coyoacan
import { style } from "../utils/style";
import { useRef } from "react";
import L from "leaflet";

const quantile = (arr, q) => {
    const asc = arr => arr.sort((a, b) => a - b);
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

function padronelectoral(shape,campo){
    let array_pe =[]
    shape.features.map(function(elem){
        array_pe.push(Number(elem.properties[campo]))
    })
    const [min,q1,q2,q3,max] = [Math.min.apply(Math,array_pe),quantile(array_pe,0.25),quantile(array_pe,0.5),quantile(array_pe,0.75),Math.max.apply(Math,array_pe)]
return [min,q1,q2,q3,max]
}

const DataComponent = ({ infoRef }) => {
  const map = useMap();
  const geojsonRef = useRef();

  // Mouseover function
  const highlightFeature = (e) => {
    var layer = e.target;

    layer.setStyle({
      weight: 1,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    infoRef.current.update(layer.feature.properties);
  };

  // Define function for `mouseout`
  const resetHighlight = (e) => {
    geojsonRef.current.resetStyle(e.target);
    infoRef.current.update(null);
  };

  const zoomToFeature = (e) => {
    map.fitBounds(e.target.getBounds());
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: zoomToFeature
    });
  };

  return (
    <GeoJSON
      data={secc_elec_shape}
      style={style}
      onEachFeature={onEachFeature}
      ref={geojsonRef}
    />
  );
};

export default DataComponent;
