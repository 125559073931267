import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";

const InfoContainer = L.Control.extend({
  initialize: function (options) {
    L.Util.setOptions(this, options);
    this.options.properties = options.properties;
  },

  onAdd: function (map) {
    this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
    this.update();
    return this._div;
  },
  
  update: function (properties) {
    if (properties !== undefined && properties !== null) {
        const suma_18_19_mujeres = properties["MUJERES 18"] + properties["MUJERES 19"]
        const suma_20_29_mujeres = properties["MUJERES 20-24"] + properties["MUJERES 25-29"]
        const suma_30_39_mujeres = properties["MUJERES 30-34"] + properties["MUJERES 35-39"]
        const suma_40_49_mujeres = properties["MUJERES 40-44"] + properties["MUJERES 45-49"]
        const suma_50_59_mujeres = properties["MUJERES 50-54"] + properties["MUJERES 55-59"]
        const suma_60_mas_mujeres = properties["MUJERES 60-64"] + properties["MUJERES 65 Y MAS"]
        const suma_18_19_hombres = properties["HOMBRES 18"] + properties["HOMBRES 19"]
        const suma_20_29_hombres = properties["HOMBRES 20-24"] + properties["HOMBRES 25-29"]
        const suma_30_39_hombres = properties["HOMBRES 30-34"] + properties["HOMBRES 35-39"]
        const suma_40_49_hombres = properties["HOMBRES 40-44"] + properties["HOMBRES 45-49"]
        const suma_50_59_hombres = properties["HOMBRES 50-54"] + properties["HOMBRES 55-59"]
        const suma_60_mas_hombres = properties["HOMBRES 60-64"] + properties["HOMBRES 65 Y MAS"]        
        this._div.innerHTML =
        "<h2>Densidad del Padrón Electoral</h2>" +
        (properties
          ? "<b>" +
            properties.name +
            "</b><br /><b>Padrón Eletoral de la Seccion Electoral: </b>" + properties["PADRON ELECTORAL TOTAL"] +  
            "</b><b><li>Mujeres: </b>" + properties["MUJERES TOTALES"] +
            "<ul>" + 
            "</b><b><li>18-19 años: </b>" + suma_18_19_mujeres +
            "</b><b><li>20-29 años: </b>" + suma_20_29_mujeres +
            "</b><b><li>30-39 años: </b>" + suma_30_39_mujeres +
            "</b><b><li>40-49 años: </b>" + suma_40_49_mujeres +
            "</b><b><li>50-59 años: </b>" + suma_50_59_mujeres +
            "</b><b><li>60+ años: </b>" + suma_60_mas_mujeres +
            "</ul>" + 
            "</b><b><li>Hombres: </b>" + properties["HOMBRES TOTALES"] + "</li>" +
            "<ul>" +
            "</b><b><li>18-19 años: </b>" + suma_18_19_hombres +
            "</b><b><li>20-29 años: </b>" + suma_20_29_hombres +
            "</b><b><li>30-39 años: </b>" + suma_30_39_hombres +
            "</b><b><li>40-49 años: </b>" + suma_40_49_hombres +
            "</b><b><li>50-59 años: </b>" + suma_50_59_hombres +
            "</b><b><li>60+ años: </b>" + suma_60_mas_hombres +            
            "</ul>" 
          : "Posicionate en una sección electoral");        
    }
  },

  onRemove: function (map) {
    // Nothing to do here
  }
});

const InfoControl = createControlComponent((props) => new InfoContainer(props));

InfoControl.defaultProps = {
  position: "topright",
};

export default InfoControl;
