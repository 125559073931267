import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import { padronelectoral } from "../utils/style";

const LegendContainer = L.Control.extend({
  onAdd: function (map) {
    var div = L.DomUtil.create("div", "info legend"),
      grades = [250, 1036, 1306, 1720, 4852],
      colores = ["#1C1AAF","#0066E7","#FFDA13","#F18009"],
      labels = [];

    // loop through our density intervals and generate a label with a colored square for each interval
    div.innerHTML = "<h4>Rangos Padrón electoral</h4>"
    for (var i = 0; i < grades.length; i++) {
      div.innerHTML +=
        '<i style="background:' +
        colores[i] +
        '"></i> ' +
        grades[i] +
        (grades[i + 1] ? "&ndash;" + grades[i + 1] + "<br>" : "+");
    }

    return div;
  },
  onRemove: function (map) {}
});

const LegendControl = createControlComponent(
  (props) => new LegendContainer(props)
);

LegendControl.defaultProps = {
  position: "bottomright"
};

export default LegendControl;


